import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";
import PubSub from "pubsub-js";

const publish = (event:PubSubJS.Message, data: string) => PubSub.publish(event, data);
const subscribe = (event:PubSubJS.Message, callback: any) => PubSub.subscribe(event, callback);

export const sipHeaderHomeScreen = {
  logoFile: "siplogo.png",
  title: "",
  brandLinks: [],
  primaryColor: "#FFFFFF",
  profile: false,
  search: false,
  isHelpVisible: false,
  dashboardLink: "/",
  isPreferenceAvailable: false,
  isSipModule: true,
};


export const sipHeaderLoginScreen = {
  logoFile: "",
  title: "Sustainable Innovation Profiler",
  defaultHomePage: "/",
  loginPageUrl: "/sip/login",
  isSipModuleLogin:true,
  // brandLinks: [
  //   {
  //     linkName: "Performance",
  //     linkHref: "ApplicationPerformance?applicationName=fred#/",
  //   },
  //   { linkName: "About", linkHref: "#" },
  //   {
  //     linkName: "Privacy Police",
  //     linkHref: "https://myapps.jnj.com/ppolicy/",
  //   },
  //   {
  //     linkName: "Legal Notice",
  //     linkHref: "https://myapps.jnj.com/ppolicy/legal.jsp",
  //   },
  // ],
  primaryColor: "rgba(2, 134, 42, 0.91)",
  profile: false,
  search: false,
  isHelpVisible: false,
  clientId: `${process.env.MS_CLIENT_ID}`,
  redirectUrl: `${process.env.REDIRECT_URI}`,
  tenantId: `${process.env.TENANT_ID}`,
  appName: `${process.env.APPNAME}`,
  isUserActivityLoginAvailable: false,
};

const layoutData = {
  loaders: {
    loader: `<div class="d-flex justify-content-center">
    <div class="spinner-border text-danger" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>`,
  },
  props: {
    sipHeaderHomeScreen: {
      ...sipHeaderHomeScreen,      
      publish,
      subscribe,
    },

    sipHeaderLoginScreen: {
      ...sipHeaderLoginScreen, 
      publish, 
      subscribe,
    },
   
    sipUiMfScreen: {
      publish, 
      subscribe,
    },
  },
};
const routes = constructRoutes(microfrontendLayout, layoutData);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();